@import '../../../../styles/customMediaQueries.css';

.sectionbody {
    display: flex;
    width: 100%;
    height: 619px;
    padding: 120px 165px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: var(--primary-1-b-242-d, #1B242D);

    @media (--viewLargeScreenMax) {
      display: flex;
      width: 100%;
      height: 861px;
      padding: 70px 53px 76.481px 53px;
      flex-direction: column;
      align-items: center;
      flex-shrink: 0;
    }
}

.sectioncontent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;

  @media (--viewLargeScreenMax) {
    align-items: center;
  }
}

.sectioncontent1 {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;

  @media (--viewLargeScreenMax) {
    align-items: center;
  }
}

.sectioncontent1main {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  gap: 337px;

  @media (--viewLargeScreenMax) {
    flex-direction: column;
    gap: 40px;
    align-items: center;
  }
}

.sectioncontent1menu {
  display: flex;
  align-items: flex-start;
  gap: 181px;

  @media (--viewLargeScreenMax) {
    flex-direction: column;
    gap: 40px;
    text-align: center;
    align-items: center;
    width: 100%;
  }
}

.sectioncontent1leftmenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;

  @media (--viewLargeScreenMax) {
    align-items: center;
  }
}

.sectioncontent1menuContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  @media (--viewLargeScreenMax) {
    width: 100%;
    align-items: center;
  }
}

.sectioncontent1rightmenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.sectionmenuitem {
  color: var(--primary-eaf-6-f-6, #EAF6F6);
  /* H5 */
  font-family: Clash Display;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 30px */

  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: var(--colorSuccessLight);
  }
}

.sectioncontent1contactus {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  @media (--viewLargeScreenMax) {
    align-items: center;
  }
}

.sectioncontent1contactustitle {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.sectioncontent1contactustitle1 {
  color: var(--primary-eaf-6-f-6, #EAF6F6);
  text-align: center;

  /* H5 */
  font-family: Clash Display;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 30px */
}

.sectioncontent1contactustitle2 {
  display: flex;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;

  color: var(--primary-eaf-6-f-6, #EAF6F6);
  text-align: right;

  /* Paragraph/P12 - Medium */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 200% */

  opacity: 0.5;
}

.emailcontainer {
  display: flex;
  padding: 1px;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  border-radius: 99px;
  border: rgba(255, 255, 255, 0.20) solid 1px;

  width: 285px;
  height: 56px;

  background: #1B242D;
}

.emailedit {
  color: rgba(234, 246, 246, 0.50);
  text-align: center;
  /* Paragraph/P12 - Medium */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 200% */
  background: transparent;
  outline: transparent;
  border: 0px;
  text-align: left;

  &:focus {
    background-color: transparent;
    box-shadow: none;
    outline: none;
    border: 0;
  }

  &:hover {
    background-color: transparent;
    outline: none;
    border: 0;
  }
}

.emailInput {
  width: 52px;
  height: 52px;
  flex-shrink: 0;
  border-radius: 99px;
  background: var(--extended-06-c-167, #06C167);

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sectioncontent2 {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 26px;
  justify-content: space-between;

  @media (--viewLargeScreenMax) {
    flex-direction: column-reverse;
  }
}

.sectioncontent2text {
  color: var(--primary-eaf-6-f-6, #EAF6F6);

  /* Paragraph/P12 - Medium */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 200% */

  opacity: 0.5;
}

.sectionsocial {
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: 85.856px;
  gap: 20px;
}

.sectionSocialItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 85.856px;
  
  & a {
    color: #EAF6F6;
    opacity: 0.5;
    &:hover {
      color: #06C167;
      opacity: 1;
    }
  }
}